import { useRecoilCallback } from "recoil";
import {
  formatSearchPageParams,
  getNumberSupplierHits,
} from "../components/ContractSearch/utils";
import {
  contractDocumentsFilterState,
  contractSearchAnalyticsParamsState,
  contractSearchParamsState,
  contractSearchTrackingCountsState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  expirationFilterState,
  supplierLocationFilterState,
  supplierSearchResponseDataState,
} from "../recoil/search";
import { SearchTypes, contractMatchTypes, resultTypes } from "../utils/enums";
import {
  type TrackContractClickProps,
  trackSerpClick,
} from "../utils/tracking";
import {
  approvedSourcesOnlyFilterState,
  searchResultTypeState,
  singleAwardOnlyFilterState,
} from "./../recoil/search";

/**
 * Use for tracking clicks before login wall. Tracks the same data as `trackContractClick`.
 */
export default function useTrackSerpClick() {
  const _trackSerpClick = useRecoilCallback<[TrackContractClickProps], void>(
    ({ snapshot }) =>
      async ({
        absoluteDepth,
        relativeDepth,
        matchTier,
        contractId,
        supplierId,
        supplierHandle,
        buyerLeadAgencyId,
        buyerLeadAgency,
        cooperativeAffiliation,
        searchSource,
        filterScore,
        semanticScore,
        groupingType,
        displayTag,
        displayTagCopy,
        resultType,
        ctaType,
      }) => {
        const [
          supplierResponseData,
          searchParams,
          analyticsParams,
          trackingCounts,
          diversityCertificationsFilter,
          approvedSourcesOnlyFilter,
          singleAwardOnlyFilter,
          contractSourceFilter,
          expirationFilter,
          supplierLocationFilter,
          contractDocumentsFilter,
          searchResultType,
        ] = await Promise.all([
          snapshot.getPromise(supplierSearchResponseDataState),
          snapshot.getPromise(contractSearchParamsState),
          snapshot.getPromise(contractSearchAnalyticsParamsState),
          snapshot.getPromise(contractSearchTrackingCountsState),
          snapshot.getPromise(diversityCertificationsFilterState),
          snapshot.getPromise(approvedSourcesOnlyFilterState),
          snapshot.getPromise(singleAwardOnlyFilterState),
          snapshot.getPromise(contractSourceFilterState),
          snapshot.getPromise(expirationFilterState),
          snapshot.getPromise(supplierLocationFilterState),
          snapshot.getPromise(contractDocumentsFilterState),
          snapshot.getPromise(searchResultTypeState),
        ]);

        if (!resultType) resultType = resultTypes.CONTRACT;
        if (matchTier === contractMatchTypes.OCR) {
          resultType = resultTypes.CONTRACT_OCR;
        }
        if (matchTier === contractMatchTypes.CATEGORY_MATCH) {
          resultType = resultTypes.CONTRACT_SEARCH_CATEGORY;
        }
        if (matchTier === contractMatchTypes.SUPPLIER_PREVIEW) {
          resultType = resultTypes.SUPPLIER_CARD_CONTRACT_PREVIEW;
        }
        if (matchTier === contractMatchTypes.SUPPLIER_SUGGESTION) {
          resultType = resultTypes.SUPPLIER_SUGGESTION;
        }
        const params = formatSearchPageParams(searchParams);
        const { requestID } = analyticsParams;
        trackSerpClick({
          ...trackingCounts,
          absoluteDepth,
          relativeDepth,
          contractId,
          buyerLeadAgencyId,
          buyerLeadAgency,
          cooperativeAffiliation,
          firstPageSupplierCount: getNumberSupplierHits(supplierResponseData),
          searchQuery: params.query,
          queryZip: params.zip,
          matchTier,
          resultType,
          searchType:
            searchResultType === "supplier"
              ? SearchTypes.SUPPLIER
              : SearchTypes.CONTRACT,
          supplierId,
          supplierHandle,
          userSelectedFromDefault: null,
          searchSource: searchSource || analyticsParams?.searchSource,
          requestID,
          filterScore,
          semanticScore,
          expirationFilter,
          diversityCertificationsFilter,
          approvedSourcesOnlyFilter,
          singleAwardOnlyFilter,
          contractSourceFilter,
          supplierLocationFilter,
          contractDocumentsFilter,
          groupingType,
          displayTag,
          displayTagCopy,
          ctaType,
        });
      },
    []
  );

  return _trackSerpClick;
}
