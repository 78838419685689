import { useEffect, useMemo, useState } from "react";

import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import type { ContractWithBuyerLeadAgency } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import { Button, ButtonSizes, ButtonThemes, Typography } from "../../library";
import {
  addPiggybackSuccessState,
  initializeRecentlyViewedContractsCallback,
  recentlyViewedContractsInitializedState,
  recentlyViewedContractsState,
} from "../../recoil/piggyback";
import { LoadingCards } from "../../shared/Loading/LoadingCards";
import Modal from "../../shared/Modal/Modal";
import SupportEmailLink from "../../shared/SupportEmailLink";
import { MODAL_SOURCE, modals } from "../../utils/enums";
import SubmitButton from "../SignupSteps/SubmitButton";
import ContractCheckbox from "./ContractCheckbox";
import GetHelpModal from "./GetHelpModal";
import usePiggybackOnSelectedContracts from "./usePiggybackOnSelectedContracts";

interface RecentlyViewedContractsModalProps {
  hideModal: () => void;
}

export default function RecentlyViewedContractsModal({
  hideModal,
}: RecentlyViewedContractsModalProps) {
  const recentlyViewedContracts = useAtomValue(recentlyViewedContractsState);
  const setRecentlyViewedContractsInitialized = useSetAtom(
    recentlyViewedContractsInitializedState
  );
  const contracts = useMemo(
    () => recentlyViewedContracts.contracts.map(({ contract }) => contract),
    [recentlyViewedContracts]
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkedContracts, setCheckedContracts] = useState<string[]>([]);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const setPiggybackSuccess = useSetAtom(addPiggybackSuccessState);
  const addSelectedContracts = usePiggybackOnSelectedContracts(
    checkedContracts,
    contracts
  );
  const initializeRecentlyViewedContracts = useAtomCallback(
    initializeRecentlyViewedContractsCallback()
  );

  useEffect(() => {
    initializeRecentlyViewedContracts();
  }, [initializeRecentlyViewedContracts]);

  const showGetHelpModal = useShowModal(modals.GET_HELP_MODAL);

  if (contracts.length === 0 && recentlyViewedContracts.isInitialized) {
    return <GetHelpModal hideModal={hideModal} />;
  }

  function handleCheckboxChange(docid: string) {
    if (checkedContracts.includes(docid)) {
      setCheckedContracts(checkedContracts.filter((id) => id !== docid));
    } else {
      setCheckedContracts([...checkedContracts, docid]);
    }
  }

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const success = await addSelectedContracts();
    if (!success) {
      setModalErrorMessage(
        "An error occurred while adding your contracts. Please try again later or contact support at:"
      );
      setIsSubmitting(false);
      return;
    }
    // Force a refetch of contracts after some are added.
    setRecentlyViewedContractsInitialized(false);
    setPiggybackSuccess(true);

    if (location.pathname !== "/entity-contracts") {
      window.open("/entity-contracts#piggybacked-contracts", "_parent");
    } else {
      window.location.hash = "piggybacked-contracts";
    }
    hideModal();
    setIsSubmitting(false);
  };

  const contractList = contracts.map(
    ({
      docid,
      title,
      suppliers,
      buyerLeadAgency,
      cooperativeAffiliation,
    }: ContractWithBuyerLeadAgency) => {
      return (
        <ContractCheckbox
          key={docid}
          docid={docid}
          title={title}
          suppliers={suppliers}
          buyerLeadAgency={buyerLeadAgency}
          cooperativeAffiliation={cooperativeAffiliation}
          checked={checkedContracts.includes(docid)}
          handleCheckboxChange={handleCheckboxChange}
        />
      );
    }
  );

  const ctas = (
    <div className="flex flex-col gap-4 items-center">
      <SubmitButton
        handleSubmit={handleSubmit}
        ctaText="Add contracts"
        isCTALoading={isSubmitting}
        disabled={isSubmitting || checkedContracts.length === 0}
      />
      <Button
        theme={ButtonThemes.TERTIARY_DARK}
        size={ButtonSizes.SMALL}
        onClick={() =>
          showGetHelpModal({ source: MODAL_SOURCE.RECENTLY_VIEWED_CONTRACTS })
        }
      >
        I didn{"'"}t use these contracts
      </Button>
      {modalErrorMessage && (
        <Typography
          color="destructive.default.primary.enabled"
          className="w-[500px]"
        >
          {modalErrorMessage} <SupportEmailLink />
        </Typography>
      )}
    </div>
  );

  return (
    <Modal
      hideModal={hideModal}
      title="Get emails when contracts are expiring"
      subtitle="Select recently viewed contracts you’re using and want to monitor."
      ctaSection={ctas}
      showLogoHeader={false}
      className="analytics-recently-viewed-contracts-modal"
    >
      {!recentlyViewedContracts.isInitialized ? (
        <LoadingCards />
      ) : (
        <div className="flex flex-col gap-2">{contractList}</div>
      )}
    </Modal>
  );
}
