import { useRecoilValue } from "recoil";

import type { ContractSearchParams } from "../../components/ContractSearch/types";
import type { SupplierCardType, SupplierMatchData } from "../../generated";
import useLoginWall from "../../hooks/useLoginWall";
import useTrackSerpClick from "../../hooks/useTrackSerpClick";
import useTrackSupplierClick from "../../hooks/useTrackSupplierClick";
import { userZipState } from "../../recoil/user";
import { getParam, goToURL, isIframe } from "../../utils";
import {
  LoginWallTriggers,
  type SearchIntent,
  ViewContractRankCTA,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import { getSupplierUrlPath } from "../../utils/format";
import SupplierCard from "../SupplierCard";
import { getSupplierTrackingData } from "./utils";

interface SupplierMatchSectionProps {
  supplierMatchData?: Maybe<SupplierMatchData>;
  suppliers: SupplierCardType[];
  params: ContractSearchParams;
  requestID: string;
  supplierCardDataTestId?: string;
}

function SupplierMatchSection({
  suppliers,
  params,
  requestID,
  supplierCardDataTestId,
}: SupplierMatchSectionProps) {
  const userZip = useRecoilValue(userZipState);
  const requireLogin = useLoginWall();
  const trackSerpClick = useTrackSerpClick();
  const trackSupplierClick = useTrackSupplierClick();

  const isWidget = isIframe();
  if (!suppliers?.length || isWidget) {
    return null;
  }

  const supplierWithContractsExists = suppliers.some(
    (supplier) => supplier.supplierDisplay.numActiveContracts
  );
  if (supplierWithContractsExists) {
    suppliers = suppliers.filter(
      (supplier) => supplier.supplierDisplay.numActiveContracts
    );
  }

  function onSupplierClick(supplier: SupplierCardType) {
    const supplierUrl = getSupplierUrlPath(
      supplier.supplier.handle,
      params.query,
      userZip,
      pageNavigationSourceTypes.SEARCH,
      requestID,
      getParam("intent") as SearchIntent
    );
    const data = getSupplierTrackingData(supplier);
    trackSupplierClick(data);
    void requireLogin({
      triggerId: supplier.supplier.handle,
      triggerType: LoginWallTriggers.CONTRACT_SEARCH_SUPPLIER_CLICK,
      onComplete: () => goToURL(supplierUrl),
    });
  }

  function trackSupplierSerpClick(supplier: SupplierCardType) {
    trackSerpClick({
      contractId: "",
      ...getSupplierTrackingData(supplier),
      ctaType: ViewContractRankCTA.SUPPLIER_PROFILE,
    });
  }

  return (
    <div className="grid gap-y-4">
      {suppliers.map((supplier) => (
        <SupplierCard
          key={supplier.supplier.id}
          supplier={supplier}
          size="md"
          className="max-w-[49.5rem] col-span-8"
          onClick={() => onSupplierClick(supplier)}
          trackSerpClick={() => trackSupplierSerpClick(supplier)}
          dataTestId={
            supplierCardDataTestId
              ? `${supplierCardDataTestId}-${supplier.supplier.handle}`
              : undefined
          }
        />
      ))}
    </div>
  );
}
export default SupplierMatchSection;
