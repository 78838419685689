import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import useShowModal from "../../../hooks/useShowModal";
import {
  initializeRecentlyViewedContractsCallback,
  recentlyViewedContractsState,
} from "../../../recoil/piggyback";
import {
  type IUserStateState,
  profileTypeState,
  userInitializedState,
  userStateState,
} from "../../../recoil/user";
import { patchUserState } from "../../../utils/api";
import { MODAL_SOURCE, ProfileType, modals } from "../../../utils/enums";

// hook to show piggyback launch modal
export default function useShowPiggybackLaunchModal() {
  const showPiggybackModal = useShowModal(modals.PIGGYBACK_LAUNCH_MODAL);
  const isInitialized = useRecoilValue(userInitializedState);
  const profileType = useRecoilValue(profileTypeState);
  const [userState, setUserState] =
    useRecoilState<IUserStateState>(userStateState);

  const initializeRecentlyViewedContracts = useAtomCallback(
    initializeRecentlyViewedContractsCallback()
  );
  const recentlyViewedContracts = useAtomValue(recentlyViewedContractsState);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Rerun this only when the user state is initialized
  useEffect(() => {
    if (
      profileType !== ProfileType.BUYER ||
      !isInitialized ||
      userState.lmPiggybackExpirations
    )
      return;
    initializeRecentlyViewedContracts();
  }, [isInitialized, userState]);

  useEffect(() => {
    if (
      !isInitialized ||
      !recentlyViewedContracts.isInitialized ||
      userState.lmPiggybackExpirations ||
      recentlyViewedContracts.contracts.length < 5
    )
      return;

    showPiggybackModal({
      updateUserState: () => {
        const updatedState = {
          ...userState,
          lmPiggybackExpirations: true,
        };
        patchUserState(updatedState);
        setUserState(updatedState);
      },
      source: MODAL_SOURCE.SITE_VISIT,
    });
  }, [
    recentlyViewedContracts,
    setUserState,
    showPiggybackModal,
    userState,
    isInitialized,
  ]);
}
