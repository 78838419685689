import { useRecoilValue } from "recoil";

import { isFreshSignupAtom } from "../../recoil/history";
import { userTypeSignupState } from "../../recoil/user";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { accountModals } from "../../utils/enums";

import { useSetAtom } from "jotai";
import { redirectOnComplete } from "../../components/Account/helpers";
import useShowModal from "../../hooks/useShowModal";
import useShowSignupSurvey from "../../hooks/useShowSignupSurvey";
import PostSocialAuthForm from "./PostSocialAuthForm";
import { WindowType } from "./types";

const subtitle = {
  buyer:
    "What public agency do you work for? This allows us to give you a tailored experience on Pavilion.",
  supplier:
    "What business do you work for? This allows us to give you a tailored experience on Pavilion.",
};

interface PostSocialAuthModalProps {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  provider?: string;
  userEmail?: string;
  firstName?: string;
}
export default function PostSocialAuthModal({
  hideModal,
  onComplete,
  isBlocking = false,
  firstName,
  provider,
  userEmail,
}: PostSocialAuthModalProps) {
  const userType = useRecoilValue(userTypeSignupState);
  const showWelcomeModal = useShowModal(accountModals.BUYER_SIGNUP_WELCOME);
  const setIsFreshSignup = useSetAtom(isFreshSignupAtom);
  const showSigupSurvey = useShowSignupSurvey();
  function goToBuyerSignupWelcome() {
    setIsFreshSignup(false);
    showWelcomeModal({
      onComplete,
    });
  }

  // Once user has completed the signup step, their account is created.
  // Hence, this modal should be blocking and user should be forced to trigger a refresh via "Skip"
  function onCompleteBuyerSignup() {
    showSigupSurvey({ onComplete: goToBuyerSignupWelcome });
  }

  function onCompleteSupplierSignup(redirectUrl: string) {
    showSigupSurvey({ onComplete: () => redirectOnComplete(redirectUrl) });
  }

  return (
    <Modal
      isBlocking={isBlocking}
      hideModal={hideModal}
      className="analytics-post-sociallogin-modal inline-flex items-center"
      title={firstName ? `Welcome, ${firstName}!` : "Welcome!"}
      subtitle={subtitle[userType]}
      modalSize={modalSizes.SMALL}
      showLogoHeader
    >
      <PostSocialAuthForm
        onComplete={
          userType === "buyer"
            ? onCompleteBuyerSignup
            : onCompleteSupplierSignup
        }
        provider={provider}
        userEmail={userEmail}
        parentWindow={WindowType.Modal}
      />
    </Modal>
  );
}
