import clsx from "clsx";
import { type Ref, forwardRef, useState } from "react";
import { useRecoilValue } from "recoil";

import { ContractScopeTypeEnum } from "../../generated";
import { Card, Link, Typography } from "../../library";

import { useAtomValue, useSetAtom } from "jotai";
import {
  productLastSearchQueryState,
  productState,
  useSearchProductServicesCallback,
} from "../../recoil/products";
import { isAuthenticatedState } from "../../recoil/user";
import { getParam, getRequestID } from "../../utils";
import { ProductListSource } from "../../utils/enums";
import { getMessageSupplierUrl } from "../../utils/format";
import {
  trackContactSupplierFromProductList,
  trackOpenProductDetail,
  trackResetSearchProductList,
  trackSearchProductList,
} from "../../utils/tracking";
import CreateAccountButton from "../CreateAccountButton";
import { ProductNavigationButtons } from "./ProductNavigationButtons";
import ProductSearchBar from "./ProductSearchBar";
import ProductTable from "./ProductTable";

interface ProductListProps {
  supplierHandle: string;
  supplierName: string;
  contractId?: string;
  supplierId: number;
  onSupplierPage?: boolean;
  contractScope?: Maybe<ContractScopeTypeEnum>;
}

const ProductList = forwardRef(
  (
    {
      supplierHandle,
      supplierName,
      contractId,
      supplierId,
      onSupplierPage,
      contractScope,
    }: ProductListProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const isAuthenticated = useRecoilValue(isAuthenticatedState);
    const query = getParam("query");

    const { isLoading, isInitialized, products, lastSearchQuery } =
      useAtomValue(productState);
    const setLastSearchQuery = useSetAtom(productLastSearchQueryState);
    const [page, setPage] = useState(0);
    const productSearch = useSearchProductServicesCallback();

    const baseTrackingParams = {
      searchQuery: query,
      productQuery: lastSearchQuery,
      contractId,
      supplierHandle,
      supplierId,
      source: onSupplierPage
        ? ProductListSource.SUPPLIER_PAGE
        : ProductListSource.CONTRACT_PAGE,
    };

    function reset() {
      setLastSearchQuery("");
      search("", 0);
      trackResetSearchProductList(baseTrackingParams);
    }

    async function search(query: string, searchPage: number) {
      if (searchPage === undefined || !isAuthenticated) {
        return;
      }

      setPage(searchPage);
      await productSearch(supplierHandle, searchPage, query);

      trackSearchProductList({
        ...baseTrackingParams,
        page: searchPage,
        productQuery: query,
      });
    }

    const onContactCtaClick = () => {
      trackContactSupplierFromProductList(baseTrackingParams);
      const url = getMessageSupplierUrl({
        handle: supplierHandle,
        query: lastSearchQuery,
        zip: null,
        requestID: getRequestID(),
      });
      window.open(url, "_blank");
    };

    const numPages = Math.ceil(products.count / products.pageSize);
    const queryPlaceholder = products.totalCount
      ? `Search ${products.totalCount.toLocaleString()} products`
      : "Search products";
    const hasPages = !isInitialized || numPages > 0;
    const showFilterText =
      lastSearchQuery && isInitialized && !isLoading && hasPages;

    const headerText = onSupplierPage ? `${supplierName} products` : "Products";

    return (
      <div ref={ref} className="flex flex-col gap-4 relative scroll-m-[10rem]">
        <Typography
          variant="headline"
          size="sm"
          emphasis
          color="brand.default.secondary.enabled"
        >
          {headerText}
        </Typography>
        <div
          className={clsx("flex flex-col gap-4", {
            "opacity-50 pointer-events-none h-60": !isAuthenticated,
          })}
        >
          {onSupplierPage ? (
            <Typography size="sm">
              <Link size="sm" emphasis={false} onClick={onContactCtaClick}>
                Contact {supplierName}
              </Link>{" "}
              to confirm which items in their catalog are covered by their
              contracts.
            </Typography>
          ) : (
            <Typography size="sm">
              {contractScope === ContractScopeTypeEnum.EVERYTHING ? (
                `All ${supplierName} items in their catalog are covered in this contract.`
              ) : (
                <>
                  <Link size="sm" emphasis={false} onClick={onContactCtaClick}>
                    Contact {supplierName}
                  </Link>
                  {
                    " to confirm whether items in their catalog are covered by this contract."
                  }
                </>
              )}
            </Typography>
          )}
          <div className="flex w-fit gap-4">
            <ProductSearchBar
              search={(query) => search(query, 0)}
              query={lastSearchQuery}
              queryPlaceholder={queryPlaceholder}
            />
          </div>
          <ProductTable
            trackOpenProductDetail={(id: string) => {
              trackOpenProductDetail({ productId: id, ...baseTrackingParams });
            }}
          />
          {showFilterText && (
            <Typography>
              Product list is filtered for "{lastSearchQuery}".{" "}
              <Link emphasis={false} onClick={reset}>
                See all products.
              </Link>
            </Typography>
          )}
          {!hasPages && !isLoading && isInitialized && (
            <Typography>
              No product results for "{lastSearchQuery}." Try a different term
              or{" "}
              <Link emphasis={false} onClick={reset}>
                browse all {supplierName} products
              </Link>
              .
            </Typography>
          )}
          {hasPages && (
            <ProductNavigationButtons
              onClick={(next) => search(lastSearchQuery, next)}
              index={page}
              count={numPages}
              isLoading={isLoading}
            />
          )}
        </div>
        {!isAuthenticated && (
          <div className="absolute z-1 w-full h-full p-6 top-0 left-0 border-0">
            <Card>
              <div className="flex flex-col justify-between items-center gap-6 rounded-lg px-12 py-6">
                <div className="flex flex-col gap-6">
                  <Typography variant="cta" size="md" className="opacity-100">
                    Product lists are only available for Pavilion users. Sign up
                    for your free account to view and search through{" "}
                    {supplierName} products.
                  </Typography>
                  <div className="flex gap-3">
                    <CreateAccountButton />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
    );
  }
);

export default ProductList;
