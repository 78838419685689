import type { ContractHit } from "../../generated";
import type { BuyerProfile } from "../../shared/types";
import {
  SearchActions,
  SearchSource,
  SearchTypes,
  ViewContractRankCTA,
  contractMatchTypes,
  landingPageType,
  resultTypes,
} from "../../utils/enums";
import {
  trackContractClick,
  trackContractSearch,
  trackLandingPageContractClick,
} from "../../utils/tracking";
import type { TrackingCountsProps } from "../ContractSearch/types";

import type { SearchParams } from "./LandingPage";

export function trackContractClickLandingPageSearch({
  hit,
  trackingCounts,
  query,
  sourceValue,
  requestID,
}: {
  hit: ContractHit;
  trackingCounts: TrackingCountsProps;
  query: string;
  sourceValue: string;
  requestID?: string;
}) {
  trackLandingPageContractClick({
    solicitationId: hit.solicitationId,
    landingPage: landingPageType.AGENCY,
    contractId: hit.docid,
    sourceValue,
  });

  if (query) {
    let resultType = resultTypes.CONTRACT;
    if (hit.matchTier === contractMatchTypes.OCR) {
      resultType = resultTypes.CONTRACT_OCR;
    }
    trackContractClick({
      ...trackingCounts,
      firstPageSupplierCount: 0,
      searchType: SearchTypes.LANDING_PAGE_SEARCH,
      resultType,
      absoluteDepth: hit.rank,
      relativeDepth: hit.rank,
      searchQuery: query,
      queryZip: "00000", // No query zip associated with the search, send 00000
      contractId: hit.docid,
      matchTier: hit.matchTier,
      buyerLeadAgencyId: hit.buyerLeadAgencyId,
      buyerLeadAgency: hit.buyerLeadAgency,
      cooperativeAffiliation: hit.cooperativeAffiliation,
      supplierId: hit.supplierId,
      supplierHandle: hit.supplierHandle,
      searchSource: SearchSource.LANDING_PAGE_ENTITY_SEARCH,
      requestID,
      ctaType: ViewContractRankCTA.VIEW_CONTRACT,
    });
  }
}

export function trackContractSearchLandingPageSearch({
  query,
  total,
  page,
  searchParams,
  trackingCounts,
  scopeMatchesCount,
  supplierNameMatchesCount,
  buyerProfile,
  requestID,
  action = SearchActions.SEARCH,
}: {
  query: string;
  total: number;
  page: number;
  searchParams: SearchParams;
  trackingCounts: TrackingCountsProps;
  scopeMatchesCount: number;
  supplierNameMatchesCount: number;
  buyerProfile: BuyerProfile;
  requestID: string;
  action: SearchActions;
}) {
  if (query) {
    trackContractSearch({
      action,
      searchQuery: query,
      geoRankingActive: false,
      resultsCount: total,
      queryZip: "00000", // No query zip associated with the search, send 00000
      searchType: SearchTypes.LANDING_PAGE_SEARCH,
      page,
      filtersAndPreferences: {
        filters: searchParams?.filters.join(","),
        params: "",
      },
      searchSource: SearchSource.LANDING_PAGE_ENTITY_SEARCH,
      govAffiliationName: buyerProfile?.governmentAffiliationDisplayName,
      govAffiliationState:
        buyerProfile?.governmentAgency?.stateCode?.toString(),
      requestID,
      hasEntityMatch: false, // This page is already an entity match
      ...trackingCounts,
      supplierCount: 0,
      scopeMatchesCount,
      supplierNameMatchesCount,
    });
  }
}
