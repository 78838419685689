import { type Getter, type Setter, atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useCallback } from "react";
import type { PiggyBackedContract } from "../components/EntityContractsPage/types";
import { ApiService, type RecentlyViewedContract } from "../generated";
import { handleError } from "../utils/generatedApi";

export const piggybackedContractsState = atom<PiggyBackedContract[]>([]);

export const piggybackedContractsLoadingState = atom(false);

export const recentlyViewedContractsListState = atom<RecentlyViewedContract[]>(
  []
);

export const recentlyViewedContractsInitializedState = atom(false);

export const recentlyViewedContractsState = atom(
  (get) => {
    return {
      contracts: get(recentlyViewedContractsListState),
      isInitialized: get(recentlyViewedContractsInitializedState),
    };
  },
  (
    _,
    set,
    value: {
      contracts: RecentlyViewedContract[];
      isInitialized: boolean;
    }
  ) => {
    set(recentlyViewedContractsListState, value.contracts);
    set(recentlyViewedContractsInitializedState, value.isInitialized);
  }
);

export const addPiggybackSuccessState = atomWithStorage(
  "addPiggybackSuccessState",
  false
);

export const removePiggybackSuccessState = atomWithStorage(
  "removePiggybackSuccessState",
  false
);

export const piggybackErrorState = atomWithStorage("piggybackErrorState", "");

export function initializeRecentlyViewedContractsCallback() {
  return useCallback(async (get: Getter, set: Setter) => {
    const recentlyViewedContracts = get(recentlyViewedContractsState);
    if (recentlyViewedContracts.isInitialized) return;

    try {
      const response = await ApiService.apiV1RecentlyViewedContractsList();
      set(recentlyViewedContractsState, {
        contracts: response,
        isInitialized: true,
      });
    } catch (error) {
      handleError(error);
      set(recentlyViewedContractsState, { contracts: [], isInitialized: true });
    }
    return recentlyViewedContractsState;
  }, []);
}
