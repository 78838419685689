import { useAtomValue } from "jotai";
import { type ReactNode, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ProjectCreationSource } from "../../components/Project/types";
import { ApiService, ItemTypeEnum } from "../../generated";
import useSaveProjectItem from "../../hooks/useSaveProjectItem";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  DropdownPicker,
  Typography,
} from "../../library";
import { savedProjectState } from "../../recoil/projects";
import { isAuthenticatedState } from "../../recoil/user";
import Modal from "../../shared/Modal/Modal";
import { handleError } from "../../utils/generatedApi";
import { useShowNewProjectModal } from "./NewProjectModal";

interface PostDownloadModalProps {
  hideModal: () => void;
  contractId: string;
}

export default function PostDownloadModal({
  hideModal,
  contractId,
}: PostDownloadModalProps) {
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const showNewProjectModal = useShowNewProjectModal();
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const [dropdownOptions, setDropdownOptions] = useState<
    { key: string; value: string; label: ReactNode; className?: string }[]
  >([]);
  const savedProject = useAtomValue(savedProjectState);
  const saveProjectItem = useSaveProjectItem(
    ProjectCreationSource.DOWNLOAD_DOCS
  );

  useEffect(() => {
    if (!isAuthenticated) return;
    (async () => {
      try {
        const projects = await ApiService.apiV1ProjectsList();
        const options = projects.map((project) => ({
          key: project.id,
          value: project.id,
          label: <Typography>{project.name}</Typography>,
        }));
        options.push({
          key: "add-project",
          value: "add-project",
          label: (
            <Typography
              className="analytics-save-project-dropdown-add-project"
              color="brand.bold.enabled"
              emphasis
            >
              + New project
            </Typography>
          ),
        });

        setDropdownOptions(options);
      } catch (e) {
        handleError(e);
      }
    })();
  }, [isAuthenticated]);

  const itemType = ItemTypeEnum.CONTRACT;

  return (
    <Modal
      hideModal={hideModal}
      title="Contract docs are downloading"
      subtitle="While your documents download, save this contract to a project for easy reference and to request quotes from suppliers."
      contentClassName="overflow-visible w-[400px] mx-auto !pt-0"
      className="analytics-post-download-modal"
      centerSubtitle={false}
    >
      <div className="flex flex-col items-center mt-4 gap-8">
        <DropdownPicker
          placeholder="Select or create a project"
          initialValue={savedProject?.id ? savedProject.id : "add-project"}
          options={dropdownOptions}
          label="Project name"
          onChange={(projectId) => {
            if (projectId === "add-project") {
              showNewProjectModal({
                source: ProjectCreationSource.DOWNLOAD_DOCS,
                itemId: contractId,
                itemType,
                onCreate: (projectId) =>
                  saveProjectItem({ projectId, itemId: contractId, itemType }),
              });
            } else {
              setSelectedProjectId(projectId);
            }
          }}
        />
        <div className="flex gap-6">
          <Button
            className="analytics-skip-save-to-project-button"
            size={ButtonSizes.LARGE}
            theme={ButtonThemes.TERTIARY_DARK}
            onClick={hideModal}
          >
            Skip
          </Button>
          <Button
            className="analytics-save-to-project-button"
            size={ButtonSizes.LARGE}
            theme={ButtonThemes.PRIMARY_DARK}
            onClick={() => {
              saveProjectItem({
                projectId: selectedProjectId,
                itemId: contractId,
                itemType: ItemTypeEnum.CONTRACT,
              });
              hideModal();
            }}
            disabled={!selectedProjectId}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
