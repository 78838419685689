import { useCallback } from "react";

import { useSetAtom } from "jotai";
import { modalState } from "../recoil/page";

export default function useShowModal(name: string) {
  const setCurrentModal = useSetAtom(modalState);
  const show = useCallback(
    (args: Record<string, unknown>) => setCurrentModal({ name, ...args }),
    [name, setCurrentModal]
  );
  return show;
}
