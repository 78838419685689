import clsx from "clsx";
import listify from "listify";
import { useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";

import { useAtomValue, useSetAtom } from "jotai";
import {
  BoldedText,
  BookmarkLink,
  ChipList,
  Link,
  Typography,
} from "../../library";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import {
  currentFileIndexState,
  selectedBookmarkIdState,
} from "../../recoil/files";
import { matchesSupplier, productState } from "../../recoil/products";
import { contractScopeState } from "../../recoil/scope";
import { searchQueryState } from "../../recoil/search";
import type { Bookmark, OCRFile } from "../../shared/types";
import {
  getSentenceCase,
  sortFilesByType,
  sortStringsWithQuery,
} from "../../utils";
import { bgColorClass } from "../../utils/colors";
import { trackScopeSummaryBookmarkClick } from "../../utils/tracking";
import { SlimProductSection } from "../ContractSearch/ProductSection";
import { getFileIndex } from "./ContractFiles/utils";

function SummarySection({
  keywords,
  renderText,
  query,
  linkClassName,
  expandText,
  dataTestId,
}: {
  keywords: string[];
  renderText: () => Maybe<string>;
  query?: string;
  linkClassName: string;
  expandText: string;
  dataTestId?: string;
}) {
  const ref = useRef(null);
  const [showDetails, setShowDetails] = useState(false);
  const text = renderText();
  if (!text) return null;

  return (
    <div ref={ref} className="grid gap-2 justify-items-start">
      <BoldedText
        highlight={query || ""}
        text={text}
        highlightColor={bgColorClass.accent.persimmon.enabled}
        textColor="neutral.boldest.enabled"
        includeSingular
      />
      {showDetails && (
        <ChipList
          parentRef={ref}
          keywords={keywords}
          query={query}
          dataTestId={dataTestId}
        />
      )}
      {!!keywords.length && (
        <Link
          className={clsx("hover:underline", linkClassName)}
          underline={false}
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? "See less" : `See all ${expandText}`}
        </Link>
      )}
    </div>
  );
}

function OfferingsSummary({
  offerings,
  summary,
  query,
}: { offerings: string[]; summary: Maybe<string>; query?: string }) {
  const renderText = () => {
    if (summary) return summary;
    if (!offerings.length) return null;

    let offeringsText = listify(
      offerings.slice(0, 3).map((v) => `"${v}"`),
      {
        finalWord: offerings.length <= 3 ? "and" : "",
      }
    );

    if (offerings.length > 3) offeringsText += ", and more";

    return `This contract offers ${offeringsText}.`;
  };

  return (
    <SummarySection
      query={query}
      renderText={renderText}
      keywords={offerings}
      linkClassName="analytics-scope-summary-offerings-toggle"
      dataTestId="contract-offerings-list"
      expandText="contract offerings"
    />
  );
}

function KeywordsSummary({
  keywords,
  supplierName,
  query,
}: { keywords: string[]; supplierName: string; query?: string }) {
  const renderText = () => {
    if (!keywords.length) return null;

    let keywordsText = listify(
      keywords.slice(0, 3).map((v) => `"${v}"`),
      {
        finalWord: keywords.length <= 3 ? "and" : "",
      }
    );

    if (keywords.length > 3) keywordsText += ", and more";

    return `${supplierName} offers ${keywordsText}.`;
  };

  return (
    <SummarySection
      query={query}
      renderText={renderText}
      keywords={keywords}
      linkClassName="analytis-scope-summary-keywords-toggle"
      expandText="supplier offerings"
    />
  );
}

function BrandsSummary({
  brands,
  query,
}: { brands: string[]; query?: string }) {
  const renderText = () => {
    if (!brands.length) return null;

    let listText = listify(
      brands.slice(0, 3).map((v) => v),
      {
        finalWord: brands.length <= 3 ? "and" : "",
      }
    );

    if (brands.length > 3) listText += ", and more";

    return `Brands on contract: ${listText}.`;
  };

  return (
    <SummarySection
      query={query}
      renderText={renderText}
      keywords={brands}
      linkClassName="analytis-scope-summary-brands-toggle"
      expandText="brands"
    />
  );
}

interface FileBookmark {
  fileWithBookmark: OCRFile;
  bookmark: Bookmark;
}

export default function ScopeSummary({
  contractDetails,
  scrollToViewer,
  handleProductListClick,
  handleDocumentsClick,
  showProductList,
}: {
  contractDetails: ContractSolicitationDetails;
  scrollToViewer: () => void;
  handleProductListClick: () => void;
  handleDocumentsClick: () => void;
  showProductList?: boolean;
}) {
  const sortedFiles = useMemo(
    () => sortFilesByType(contractDetails.file_information),
    [contractDetails.file_information]
  );
  const setCurrentFileIndex = useSetAtom(currentFileIndexState);
  const setSelectedBookmarkId = useSetAtom(selectedBookmarkIdState);
  const query = useRecoilValue(searchQueryState);
  const { initialProducts } = useAtomValue(productState);
  const { scope } = useAtomValue(contractScopeState);

  const sortedKeywords = useMemo(() => {
    if (!contractDetails.supplier_keywords?.length) return [];

    if (scope.keywords.length) {
      return scope.keywords.map(({ keyword }) => keyword).map(getSentenceCase);
    }

    const capitalizedKeywords =
      contractDetails.supplier_keywords.map(getSentenceCase);
    return sortStringsWithQuery(capitalizedKeywords, query);
  }, [contractDetails, query, scope.keywords]);

  const sortedOfferings = useMemo(() => {
    if (!contractDetails.offerings?.length) return [];

    if (scope.offerings.length) {
      return scope.offerings
        .map(({ offering }) => offering)
        .map(getSentenceCase);
    }

    const capitalizedOfferings = contractDetails.offerings.map(getSentenceCase);
    return sortStringsWithQuery(capitalizedOfferings, query);
  }, [contractDetails, query, scope.offerings]);

  const fileBookmark = useMemo(() => {
    if (!sortedFiles.length) return null;
    const file = sortedFiles.find((f) => f.bookmarks.length);
    if (!file) return null;
    return {
      fileWithBookmark: file,
      bookmark: file?.bookmarks[0],
    } as FileBookmark;
  }, [sortedFiles]);

  const handleBookmarkClick = ({
    fileWithBookmark,
    bookmark,
  }: FileBookmark) => {
    const fileIndex = getFileIndex(sortedFiles, fileWithBookmark.id);
    if (fileIndex === -1) return;
    setCurrentFileIndex(fileIndex);
    // Use the first bookmark available
    setSelectedBookmarkId(bookmark.id);
    scrollToViewer();
  };

  const bookmarkType =
    fileBookmark?.bookmark.bookmark_type || "contract documents";
  const queryMatchesSupplier = matchesSupplier(
    query || "",
    contractDetails.supplier.handle,
    contractDetails.supplier.displayName
  );
  const showProductSummary = showProductList && query && !queryMatchesSupplier;

  return (
    <div className="grid gap-4 h-fit">
      <div className="flex flex-row justify-between">
        <Typography
          variant="headline"
          size="sm"
          emphasis
          color="brand.boldest.enabled"
        >
          Scope summary
        </Typography>
        <div className="flex flex-row gap-4">
          {showProductList && (
            <BookmarkLink
              onClick={() => {
                trackScopeSummaryBookmarkClick({
                  bookmarkType: "supplier products",
                });
                handleProductListClick();
              }}
              label="Jump to products"
              background
            />
          )}
          <BookmarkLink
            onClick={() => {
              trackScopeSummaryBookmarkClick({
                bookmarkType,
              });
              if (fileBookmark) handleBookmarkClick(fileBookmark);
              else handleDocumentsClick();
            }}
            label={`Jump to ${fileBookmark ? bookmarkType.toLowerCase() : "docs"}`}
            background
          />
        </div>
      </div>
      <OfferingsSummary
        offerings={sortedOfferings}
        summary={contractDetails.summary}
        query={queryMatchesSupplier ? "" : query}
      />
      <BrandsSummary
        brands={contractDetails.brands}
        query={queryMatchesSupplier ? "" : query}
      />
      <KeywordsSummary
        keywords={sortedKeywords}
        supplierName={contractDetails.supplier.displayName}
        query={queryMatchesSupplier ? "" : query}
      />
      {showProductSummary && (
        <SlimProductSection
          products={initialProducts.hits}
          onClick={handleProductListClick}
        />
      )}
    </div>
  );
}
