import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";

import searchNoResults from "../../../img/icons/search-no-results.svg";
import useShowModal from "../../hooks/useShowModal";
import {
  Bullets,
  Button,
  ButtonSizes,
  ButtonThemes,
  Card,
  Typography,
} from "../../library";
import CloseButton from "../../library/Button/CloseButton";
import {
  contractSearchResponseDataState,
  noExactMatchesVariantState,
  numFiltersAppliedState,
  requestIDState,
  searchQueryState,
} from "../../recoil/search";
import SuggestedSearches from "../../shared/SuggestedSearches";
import { isIframe } from "../../utils";
import { bgColorClass } from "../../utils/colors";
import { MODAL_SOURCE, SearchSource, modals } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/split";
import {
  trackNoExactSearchResults,
  trackSearchRedirectCTAClick,
} from "../../utils/tracking";
import MobileSearchButton from "../MobileSearchButton";
import SearchBar, { SearchBarCtaTypes, SearchBarSizes } from "../SearchBar";
import { SearchBarThemes } from "../SearchBar/types";

export enum NoExactMatchesVariants {
  NO_RESULTS = "noResults",
  RESTRICTIVE_FILTERS = "restrictiveFilters",
  OTHER_RESULTS = "otherResults",
  NULL = "null",
}

interface VariantProps {
  title: string;
  subtitle: string;
  ctas?: { text: string; onClick: () => void }[];
  suggestedSearchTitle?: string;
  suggestedSearchTerms?: string[];
  showSearchBar: boolean;
  showSuggestedSearches: boolean;
  showSearchTips: boolean;
  searchTipsTitle: string;
  searchTips: string[];
  showGetHelpSection: boolean;
  showImage: boolean;
  showShadow: boolean;
  showCloseButton: boolean;
  bgColor: string;
}

const VARIANT_CONFIG: Record<NoExactMatchesVariants, VariantProps> = {
  [NoExactMatchesVariants.NO_RESULTS]: {
    title: "We couldn't find any exact matches",
    subtitle: "",
    suggestedSearchTitle: "",
    showSearchBar: true,
    showSuggestedSearches: false,
    showSearchTips: true,
    searchTipsTitle: "Here are a few things you can try:",
    searchTips: [
      "Check your spelling",
      "Remove technical jargon",
      "Use a synonym",
      "Search for the product or service category",
    ],
    showGetHelpSection: true,
    showImage: true,
    showShadow: false,
    showCloseButton: false,
    bgColor: bgColorClass.accent.persimmon.subtle,
  },
  [NoExactMatchesVariants.RESTRICTIVE_FILTERS]: {
    title: "No exact matches found",
    subtitle:
      "Try changing or removing some of your filters, or update your search terms.",
    suggestedSearchTitle: "",
    showSearchBar: false,
    showSuggestedSearches: false,
    searchTipsTitle: "",
    showSearchTips: false,
    searchTips: [],
    showGetHelpSection: false,
    showImage: false,
    showShadow: false,
    showCloseButton: false,
    bgColor: bgColorClass.accent.persimmon.subtle,
  },
  [NoExactMatchesVariants.OTHER_RESULTS]: {
    title:
      "Need other options? Fine-tune your search by trying other search terms.",
    subtitle: "",
    suggestedSearchTitle: "",
    showSearchBar: true,
    showSuggestedSearches: false,
    showSearchTips: true,
    searchTipsTitle: "",
    searchTips: [
      "Search for the product or service category",
      "Use a synonym",
      "Remove technical jargon",
    ],
    showGetHelpSection: false,
    showImage: false,
    showShadow: false,
    showCloseButton: false,
    bgColor: bgColorClass.brand.subtlest.enabled,
  },
  [NoExactMatchesVariants.NULL]: {
    title: "",
    subtitle: "",
    showSearchBar: false,
    showSuggestedSearches: false,
    showSearchTips: false,
    searchTipsTitle: "",
    searchTips: [],
    showGetHelpSection: false,
    showImage: false,
    showShadow: false,
    showCloseButton: false,
    bgColor: bgColorClass.neutral.subtlest.enabled,
  },
};

export default function NoExactMatches({ className }: { className?: string }) {
  const variant = useRecoilValue(noExactMatchesVariantState);
  const {
    subtitle,
    suggestedSearchTerms,
    suggestedSearchTitle,
    ctas,
    showShadow,
    bgColor,
    showSearchTips,
    searchTipsTitle,
    searchTips,
    showSearchBar,
    showCloseButton,
    showImage,
    showSuggestedSearches,
  } = VARIANT_CONFIG[variant];
  const requestID = useRecoilValue(requestIDState);
  const query = useRecoilValue(searchQueryState);
  const numAllResults =
    useRecoilValue(contractSearchResponseDataState)?.contractData
      ?.numAllResults || 0;
  const numActiveFilters = useRecoilValue(numFiltersAppliedState);
  const [show, setShow] = useState(true);
  const showSearchModal = useShowModal(modals.SEARCH);
  const showSearchFeedbackModal = useShowModal(modals.SEARCH_FEEDBACK);
  const prevRequestIDRef = useRef("");

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this function to run only when the variant or requestID changes
  useEffect(() => {
    // only track new searches
    if (requestID === prevRequestIDRef.current) return;
    prevRequestIDRef.current = requestID;

    if (
      [
        NoExactMatchesVariants.NULL,
        NoExactMatchesVariants.OTHER_RESULTS,
      ].includes(variant)
    )
      return;
    trackNoExactSearchResults({
      searchQuery: query,
      numberResults: numAllResults,
      searchBarShown: numActiveFilters === 0,
      changeFilterPromptShown: numActiveFilters > 0,
      searchRedirectType: variant,
      requestID,
    });
  }, [variant, requestID]);

  if (variant === NoExactMatchesVariants.NULL) return null;
  const title = getTitle(query, variant);
  const showGetHelpSection = isIframe()
    ? false
    : VARIANT_CONFIG[variant].showGetHelpSection;

  function onEmptyQuery() {
    showSearchModal({
      source: SearchSource.BUYER_WELCOME_PAGE,
    });
  }

  function handleGetHelpClick() {
    showSearchFeedbackModal({
      query,
      requestID,
      source: MODAL_SOURCE.SEARCH_BAR_CARD,
    });
  }

  function cbOnSearchRedirect(newSearchQuery: string) {
    trackSearchRedirectCTAClick({
      oldSearchQuery: query,
      newSearchQuery,
      requestID: requestID || "",
    });
  }

  if (!show) return null;

  return (
    <Card
      showShadow={showShadow}
      className={clsx("flex flex-col gap-6 p-6", className)}
      bgColor={bgColor}
    >
      <div className="flex justify-between relative">
        <div className="flex flex-col gap-2">
          <Typography
            variant="headline"
            size="xs"
            color="brand.boldest.enabled"
            emphasis
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography color="neutral.bolder.enabled" size="sm">
              {subtitle}
            </Typography>
          )}
          {showSearchTips && (
            <div className="analytics-search-tips-container">
              <Typography color="neutral.bolder.enabled">
                {searchTipsTitle}
              </Typography>
              <Bullets
                columns={1}
                itemsList={searchTips}
                capitalize={false}
                itemClassName="text-cp-neutral-palette-800"
              />
            </div>
          )}
          {showSearchBar && (
            <div className="flex">
              <SearchBar
                className="hidden md:flex mt-4"
                cbOnEmptyQuery={onEmptyQuery}
                searchSource={SearchSource.CONTRACTS_SERP_SEARCH}
                size={SearchBarSizes.FULL}
                theme={SearchBarThemes.DARK}
                submitCta={SearchBarCtaTypes.TEXT}
                cbOnSearchRedirect={cbOnSearchRedirect}
                disambiguationModalEnabled={isFeatureEnabled(
                  "disambiguationModal"
                )}
              />
              <MobileSearchButton
                searchSource={SearchSource.CONTRACTS_SERP_SEARCH}
              />
            </div>
          )}
          {showSuggestedSearches && (
            <SuggestedSearches
              title={suggestedSearchTitle}
              searchSource={SearchSource.CONTRACTS_SERP_SEARCH}
              searchTerms={suggestedSearchTerms}
            />
          )}
          {ctas && (
            <div className="flex flex-col md:flex-row gap-2">
              {ctas.map(({ text, onClick }) => (
                <Button
                  key={text}
                  theme={ButtonThemes.PRIMARY_LIGHT}
                  size={ButtonSizes.SMALL}
                  onClick={onClick}
                  className="w-fit"
                >
                  {text}
                </Button>
              ))}
            </div>
          )}
          {showCloseButton && (
            <CloseButton
              onClose={() => setShow(false)}
              className="absolute right-0"
              color="neutral.bold.enabled"
            />
          )}
        </div>
        {showImage && (
          <div>
            <img
              src={searchNoResults}
              alt="Search contracts"
              className="hidden sm:block w-full max-w-[250px]"
            />
          </div>
        )}
      </div>
      {showGetHelpSection && (
        <>
          <hr />
          <div className="flex flex-col gap-2 max-w-[500px]">
            <Typography
              color="brand.boldest.enabled"
              variant="headline"
              size="xs"
              emphasis
            >
              Need help finding a contract you can use?
            </Typography>
            <Typography color="neutral.bolder.enabled">
              Tell us more about your need, and our team will do additional
              research to help you find a contract you can use.
            </Typography>
            <Button
              size={ButtonSizes.SMALL}
              className="w-fit mt-4"
              onClick={handleGetHelpClick}
            >
              Help me find a contract
            </Button>
          </div>
        </>
      )}
    </Card>
  );
}

function getTitle(query: string, variant: NoExactMatchesVariants) {
  if (!query) return VARIANT_CONFIG[variant].title;

  if (
    variant === NoExactMatchesVariants.NO_RESULTS ||
    variant === NoExactMatchesVariants.RESTRICTIVE_FILTERS
  )
    return `${VARIANT_CONFIG[variant].title} for "${query}"`;

  return VARIANT_CONFIG[variant].title;
}
