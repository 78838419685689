import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { accountModals } from "../../utils/enums";
import { trackAccountAccessToggle } from "../../utils/tracking";
import { WindowType } from "../SignupSteps/types";

import type { ExistingUser } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import LoginForm from "./LoginForm";

interface LoginModalProp {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  initialEmail?: string;
}

export default function LoginModal({
  hideModal,
  onComplete,
  isBlocking = false,
  initialEmail,
}: LoginModalProp) {
  const showSignupModal = useShowModal(accountModals.INITIAL_SIGN_UP);
  const showLoginModal = useShowModal(accountModals.SIGNUP_STEPS_LOGIN);
  const showPushToLoginModal = useShowModal(accountModals.PUSH_TO_LOGIN);

  function goToSignupModal() {
    showSignupModal({
      isBlocking,
      showGoBackToSearch: false,
      initialEmail,
      onComplete,
    });
    trackAccountAccessToggle({
      toggle: "sign up",
      loginExperience: WindowType.Page,
    });
  }

  function goBack() {
    showLoginModal({
      initialEmail,
      onComplete,
    });
  }

  const redirectSocialUser = (data: ExistingUser) => {
    if (data.existingUser && data.socialAccountProvider) {
      showPushToLoginModal({
        onComplete,
        isBlocking,
        goBack,
        socialLoginSource: data.socialAccountProvider,
      });
      return true;
    }
    return false;
  };

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      className="analytics-login-modal account"
      contentClassName="no-scrollbar"
      formID="analytics-login-modal-submit"
      title="Welcome back!"
      subtitle="Log into your account."
      modalSize={modalSizes.SMALL}
      showLogoHeader
    >
      <LoginForm
        onComplete={onComplete}
        initialEmail={initialEmail}
        goToSignupPageOrModal={goToSignupModal}
        redirectSocialUser={redirectSocialUser}
        parentWindow={WindowType.Modal}
      />
    </Modal>
  );
}
