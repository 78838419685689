import { getParams } from "../../utils";
import { SearchTypes } from "../../utils/enums";
import { getContractPath } from "../../utils/format";

export default function getContractUrl(
  solicitationId: string,
  docid: string,
  query: Maybe<string>,
  queryZip: string,
  hasAutoExtractedOfferingMatch: boolean,
  hasManualOfferingMatch: boolean,
  expirationFilter: string,
  contractSourcesFilter: string[],
  pageNavigationSource?: string,
  requestID?: Maybe<string>,
  intent?: Maybe<string>
) {
  // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAMS IN THE URL.
  // PLEASE COORDINATE IF THESE CHANGE.
  const contractUrl = getContractPath({
    solicitationId,
    contractId: docid,
    query,
    queryZip,
    requestID,
  });

  contractUrl.searchParams.append("searchType", SearchTypes.CONTRACT);
  contractUrl.searchParams.append("resultType", SearchTypes.CONTRACT);
  contractUrl.searchParams.append(
    "hasAutoExtractedOfferingMatch",
    hasAutoExtractedOfferingMatch.toString()
  );
  contractUrl.searchParams.append(
    "hasManualOfferingMatch",
    hasManualOfferingMatch.toString()
  );
  contractUrl.searchParams.append("expirationFilter", expirationFilter);
  contractUrl.searchParams.append(
    "contractSourcesFilter",
    contractSourcesFilter.join(",")
  );

  const searchSource = getParams()["analytics-search-source"] as string;
  if (searchSource) {
    contractUrl.searchParams.append("analytics-search-source", searchSource);
  }
  if (pageNavigationSource) {
    contractUrl.searchParams.append(
      "pageNavigationSource",
      pageNavigationSource
    );
  }
  if (intent) {
    contractUrl.searchParams.append("intent", intent);
  }

  return contractUrl;
}
