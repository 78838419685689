import { Suspense, lazy } from "react";

import useShowModal from "../../hooks/useShowModal";
import { Button, Typography } from "../../library";
import { isIframe } from "../../utils";
import { modals } from "../../utils/enums";

const NoSearchResults = lazy(() => import("../../components/NoSearchResults"));

enum ContentTypes {
  SEARCH_RESULTS = "search_results",
  CONTRACT = "contract",
  SUPPLIER = "supplier",
  SUPPLIER_ENTITY_RECOMMENDATIONS = "supplier_entity_recommendations",
}

const DEFAULT_TITLE = "Oh no! Page not found";
const DEFAULT_DESCRIPTION =
  "We couldn't find the page you requested. Please email our team if you need help.";
const IFRAME_SEARCH_RESULTS_DESCRIPTION =
  "Try a different term or check the spelling in your search.";

const ContentNotFoundMap = {
  [ContentTypes.SEARCH_RESULTS]: {
    title: "Oh no! No matches found",
    description:
      "Try a different term or check the spelling in your search. If you still can’t find what you’re looking for, contact our team for assistance.",
  },
  [ContentTypes.CONTRACT]: {
    title: "Oh no! Contract not found",
    description:
      "We couldn't find the contract that you're looking for. You can try a different search in the search bar above. If you still can’t find what you’re looking for, email us for help.",
  },
  [ContentTypes.SUPPLIER]: {
    title: "Oh no! Supplier not found",
    description:
      "We couldn't find the supplier that you're looking for. Please email our team if you need help.",
  },
  [ContentTypes.SUPPLIER_ENTITY_RECOMMENDATIONS]: {
    title: "Oh no! No suppliers found",
    description: "No suppliers found for this entity type",
  },
};

interface ContentNotFoundProps {
  contentType: ContentTypes;
}

export default function ContentNotFound({ contentType }: ContentNotFoundProps) {
  const showFeedbackModal = useShowModal(modals.SEARCH_FEEDBACK);
  const isInIframe = isIframe();
  const title = ContentNotFoundMap[contentType]?.title
    ? ContentNotFoundMap[contentType].title
    : DEFAULT_TITLE;

  const getDescription = () => {
    if (isInIframe) {
      return IFRAME_SEARCH_RESULTS_DESCRIPTION;
    }
    if (ContentNotFoundMap[contentType]?.description) {
      return ContentNotFoundMap[contentType].description;
    }
    return DEFAULT_DESCRIPTION;
  };

  function handleClick() {
    showFeedbackModal({});
  }

  return (
    <div className="mt-6 text-center w-5/12 m-auto flex flex-col justify-between">
      <Suspense fallback={<div className="h-[236px]" />}>
        <NoSearchResults className="mx-auto mb-6 w-0 md:w-auto md:max-h-1/4-screen" />
      </Suspense>
      <div>
        <Typography
          variant="headline"
          size="lg"
          color="brand.bold"
          emphasis
          className="text-center"
        >
          {title}
        </Typography>
        <Typography color="subtler" className="flex-wrap mt-6 mb-6">
          {getDescription()}
        </Typography>
        {!isInIframe && (
          <Button
            className="feedback-cta-btn analytics-request-help-modal mb-16 p-3 w-40 h-12"
            onClick={handleClick}
            size={Button.sizes.SMALL}
          >
            Get help
          </Button>
        )}
      </div>
    </div>
  );
}
