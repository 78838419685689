import clsx from "clsx";

import useShowModal from "../hooks/useShowModal";
import { Button } from "../library";
import { modals } from "../utils/enums";

interface MobileSearchButtonProps {
  className?: string;
  searchSource: string;
}

export default function MobileSearchButton({
  className,
  searchSource,
}: MobileSearchButtonProps) {
  const showSearchModal = useShowModal(modals.SEARCH);
  const onClick = () => {
    showSearchModal({
      source: searchSource,
    });
  };

  return (
    <Button
      className={clsx(
        "analytics-mobile-search md:hidden px-3 py-2 shrink-0 text-cp-body-sm",
        className
      )}
      size={Button.sizes.SMALL}
      onClick={onClick}
    >
      <span>Search for free</span>
    </Button>
  );
}
